import Vue from 'vue'
import Router from 'vue-router'

// Middleware
import seo from '@/middlewares/seo';
import auth from '@/middlewares/auth';

// Shell
import Shell from '../Shell'

// Pages
import login from '@/pages/Login';
import register from '@/pages/Login/register';
import forgot_password from '@/pages/Login/forgot-password';
import reset_password from '@/pages/Login/reset-password';

// Authenticated Routes
import authenticated_routes from './routes'


Vue.use(Router)

const router = new Router({
    mode: 'history',
    routes: [
        {
          path: '/',
          component: Shell,
          children: authenticated_routes,
          beforeEnter: auth
        },
        {
            path: '/login',
            name: 'login',
            component: login,
            beforeEnter: seo,
            meta: {
              title: 'Museo - Login',
              metaTags: [
                {
                  name: 'description',
                  content: 'The home page of our example app.'
                },
                {
                  property: 'og:description',
                  content: 'The home page of our example app.'
                }
              ]
            }
        },
        {
          path: '/login/register',
          name: 'register',
          component: register,
          beforeEnter: seo,
          meta: {
            title: 'Museo - Register',
            metaTags: [
              {
                name: 'description',
                content: 'The home page of our example app.'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              }
            ]
          }
        },
        {
          path: '/login/forgot-my-password',
          name: 'forgot-password',
          component: forgot_password,
          beforeEnter: seo,
          meta: {
            title: 'Museo - Forgot my password',
            metaTags: [
              {
                name: 'description',
                content: 'The home page of our example app.'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              }
            ]
          }
        },
        {
          path: '/login/reset-password',
          name: 'reset-password',
          component: reset_password,
          beforeEnter: seo,
          meta: {
            title: 'Museo - Forgot my password',
            metaTags: [
              {
                name: 'description',
                content: 'The home page of our example app.'
              },
              {
                property: 'og:description',
                content: 'The home page of our example app.'
              }
            ]
          }
        },
    ]
});

export default router;