<template>
  <div class="flex flex-col m-4 shadow border bg-white dark:bg-gray-800 rounded-md flex items-center p-4">
    <div class="w-full">
      <p class="text-2xl text-gray-800 dark:text-white mb-4">
      Exhibit Schedule
      </p>

      <div class="p-4 text-right z-20 flex flex-row justify-end h-full flex-end">
        <button
          @click="clear_schedule()"
          type="button"
          class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
          Clear Schedule
        </button>
      </div>

      <section>
        <modal
          classes="bg-white dark:bg-gray-800 rounded-md p-4"
          height="auto"
          name="schedule_modal">
            <div class="w-full items-right h-16 z-40">
              <div
                v-if="!edit_mode"
                class="p-4 text-right z-20 flex flex-row justify-end h-full flex-end">  
                  <button
                    :disabled="!schedule_item.artefact.id"
                      @click="commit_schedule_item()"
                      type="button"
                      class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Save
                  </button>

                  <button
                      @click="$modal.hide('schedule_modal')"
                      type="button"
                      class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                      Cancel
                  </button>
              </div>
              <div
                v-else
                class="p-4 text-right z-20 flex flex-row justify-end h-full flex-end">  
                  <button
                      @click="commit_schedule_item()"
                      type="button"
                      class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                      Update
                  </button>

                  <button
                      @click="clear_schedule_item()"
                      type="button"
                      class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                      Clear
                  </button>

                  <button
                    @click="$modal.hide('schedule_modal')"
                    type="button"
                    class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg">
                    Cancel
                </button>
              </div>
          </div>
          <div class="flex flex-col w-full mb-4">
            <p>Start date: {{ schedule_item.range.start }}</p>
            <p>End date: {{ schedule_item.range.end }}</p>
            <p>Artefact: {{ schedule_item.artefact.title }} </p>
          </div>

          <v-date-picker v-model="schedule_item.range" :model-config="modelConfig" is-range />

          <section v-if="artefacts">
            <p class="text-2xl text-gray-800 dark:text-white">
              Scheduled Artefact:
            </p>
            <p class="text-md text-gray-500 dark:text-gray-400 py-4 font-light">
              Select the artefact you wish to link to this exhibit.
            </p>
            <div class="container flex flex-col mx-auto w-full h-auto max-h-60 items-center justify-center">
              <ul class="flex flex-col overflow-y-scroll">
                <li
                  class="flex flex-row mb-2 rounded-md"
                  :class="schedule_item.artefact_id && artefact.id == schedule_item.artefact_id ? 'border-blue-700 border-4' : 'border-gray-400'"
                  v-for="artefact in artefacts" :key="artefact.id"
                  @click="set_scheduled_artefact(artefact)"
                >
                  <div
                    class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                    <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                      <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Museo">
                    </div>
                    <div class="flex-1 pl-1 md:mr-16">
                      <div class="font-medium dark:text-white">
                        {{ artefact.title }}
                      </div>
                      <div class="text-gray-600 dark:text-gray-200 text-sm">
                        {{ artefact.description }}
                      </div>
                      <div class="text-gray-600 dark:text-gray-200 text-xs">
                        updated: {{ new Date(artefact.updated_at) }}
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </section>
        </modal>
      </section>

      <v-calendar
        is-expanded
        :columns="4"
        :attributes='schedule_items'
        @dayclick="onDayClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
  props: ["artefacts"],
  data () {
    return {
      edit_mode: false,
      schedule_item: {
        artefact: {},
        artefact_id: "",
        range: {
          start: new Date(),
          end: new Date(),
        },
      },
      modelConfig: {
        start: {
          timeAdjust: '00:00:00',
        },
        end: {
          timeAdjust: '23:59:59',
        },
      },
    }
  },

  methods: {
    onDayClick (day) {
      this.$modal.show("schedule_modal");
      this.edit_mode = false;
      let start_date = new Date(day.id);
      let end_date = new Date(day.id);

      
      if (day.attributes && day.attributes.length) {
        this.edit_mode = true;
        const item = day.attributes[0];
        this.schedule_item.artefact_id = item.customData.artefact_id
        this.schedule_item.key = item.key;
        start_date = new Date(item.dates[0].start)
        // If end date set
        if (item.dates.length > 1) {
          end_date = new Date(item.dates[1].end)
        } else {
          end_date = new Date(item.dates[0].end)
        }
      } else {
        this.schedule_item.artefact = {};
        end_date.setDate(end_date.getDate() + 1);
        end_date.setHours(23,59,59,999);
      }

      this.schedule_item.range.start = start_date;
      this.schedule_item.range.end = end_date;
    },

    set_scheduled_artefact (artefact) {
      this.schedule_item.artefact = artefact;
      this.schedule_item.artefact_id = artefact.id;
    },

    commit_schedule_item () {
      if (this.schedule_items.length > 25)
      {
        this.$notify({
            group: 'foo',
            type: 'error',
            title: 'Error',
            text: "You may only schedule 25 items per Exhibit."
        });
      }
      else
      {
        this.$store.dispatch('exhibit/add_schedule_item', this.schedule_item);
        this.$modal.hide("schedule_modal")
      }
    },

    clear_schedule_item () {
      this.$store.dispatch('exhibit/remove_schedule_item', this.schedule_item);
      this.$modal.hide("schedule_modal")
    },

    clear_schedule () {
      this.$store.dispatch('exhibit/clear_schedule');
    }
  },

  computed: {
    ...mapGetters({
      schedule_items: 'exhibit/schedule_items'
    })
  }
}
</script>