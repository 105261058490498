import index from '@/pages'

// Artefact
import artefact_index from '@/pages/Artefact';
import artefact_edit from '@/pages/Artefact/edit';

// Exhibit
import exhibit_index from '@/pages/Exhibit';
import exhibit_edit from '@/pages/Exhibit/edit';

const routes = [
  {
    path: '/',
    name: 'index',
    component: index,
    meta: {
      title: 'Museo',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/artefacts',
    name: 'artefacts',
    component: artefact_index,
    meta: {
      title: 'Museo - Artefacts',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/artefacts/:id',
    name: 'artefacts_edit',
    component: artefact_edit,
    meta: {
      title: 'Museo - Edit Artefact',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/exhibits',
    name: 'exhibits',
    component: exhibit_index,
    meta: {
      title: 'Museo - Exhibits',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },
  {
    path: '/exhibits/:id',
    name: 'exhibits_edit',
    component: exhibit_edit,
    meta: {
      title: 'Museo - Edit Exhibit',
      metaTags: [
        {
          name: 'description',
          content: 'The home page of our example app.'
        },
        {
          property: 'og:description',
          content: 'The home page of our example app.'
        }
      ]
    }
  },  
];

export default routes;