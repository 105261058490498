<template>
    <div>
        <div class="w-full mb-4 shadow-lg bg-white dark:bg-gray-700 items-right h-16 rounded-2xl z-40">
            <div class="p-4 text-right z-20 flex flex-row justify-end h-full flex-end">
                <Button
                    @click.native="create_artefact()"
                    btntype="primary"
                    :loading="loading">
                    <i class="fas fa-plus-circle"></i> Create new Artefact
                </Button>
            </div>
        </div>
        <div class="container flex flex-col mx-auto w-full items-center justify-center">
            <ul class="flex flex-col">
                <li class="border-gray-400 flex flex-row mb-2" v-for="artefact in artefacts" :key="artefact.id">
                    <router-link :to="'/artefacts/' + artefact.id" class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                        <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                            <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Museo">
                        </div>
                        <div class="flex-1 pl-1 md:mr-16">
                            <div class="font-medium dark:text-white">
                                {{ artefact.title }}
                            </div>
                            <div class="text-gray-600 dark:text-gray-200 text-sm">
                                {{ artefact.description }}
                            </div>
                        </div>
                        <div class="text-gray-600 dark:text-gray-200 text-xs">
                            updated: {{ new Date(artefact.updated_at) }}
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import RestResource from '@/services/RestResource'
import Button from "@/components/Global/Button";
export default {
    components: {
        Button
    },

    data () {
        return {
            artefacts: [],
            loading: false
        }
    },

    created () {
        this.get_artefacts()
    },
    
    methods: {
        async get_artefacts () {
            const restResouceService = new RestResource();
            const { data } = await restResouceService.GETRequest('artefacts')
            this.artefacts = data.data;
        },

        async create_artefact () {
            console.log(process.env.VUE_APP_TEMPLATE_ID)
            try {
                this.loading = true;
                const artefact_data = {
                    title: "New Artefact",
                    description: "New artefact ready for content",
                    template_id: process.env.VUE_APP_TEMPLATE_ID
                };
                const restResouceService = new RestResource();
                const { data } = await restResouceService.POSTRequest('artefacts', artefact_data);
                this.artefacts.unshift(data.data);

                this.loading = false;

                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Artefact Created ',
                    text: 'Successfully created artefact.'
                });
            } catch (err) {
                console.log(err)
                this.loading = false;
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error ',
                    text: err.message
                });
            }
        }
    }
}
</script>