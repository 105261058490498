/* eslint-disable */
import axios from 'axios'
import router from '../../router'
import { AUTH_REQUEST, AUTH_ERROR, AUTH_SUCCESS, AUTH_LOGOUT, AUTH_REFRESH, AUTH_SET_TOKEN } from '../actions/auth'
import { USER_SAVE } from '../actions/user'

const INSTANCE = axios.create({
  baseURL: process.env.VUE_APP_API_ROUTE,
  timeout: 300000
})

const state = {
  expires_at: '',
  token: '',
  refresh_token: '',
  status: ''
}

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
}

const actions = {
  [AUTH_REQUEST]: async ({ commit, dispatch }, payload) => {
    commit(AUTH_REQUEST)
    try {
      let response
      if (payload.otp) {
        response = await INSTANCE.put('two-factor', payload)
      } else {
        response = await INSTANCE.post('auth', payload)
        if (response.data.TFA) {
          return response
        }
      }

      const { data } = response
      let token = data.token

      axios.defaults.headers.common['Authorization'] = `Bearer ${token}`
      dispatch(USER_SAVE, data.user)
      commit(AUTH_SUCCESS, response)
      return response
    } catch (error) {
      commit(AUTH_ERROR, error)
      localStorage.clear()
      router.push('/login')
      throw error
    }
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    commit(AUTH_LOGOUT)
    router.push('/login')
    localStorage.clear()
  },
  [AUTH_REFRESH]: ({ commit }, data) => {
    commit(AUTH_REFRESH, data)
  },
  [AUTH_SET_TOKEN]: ({ commit }, newToken) => {
    commit(AUTH_SET_TOKEN, newToken)
  }
}

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = 'loading'
  },
  [AUTH_SUCCESS]: (state, response) => {
    const { user } = response.data.user
    const token = response.data.token
    const refresh_token = response.data.refresh_token;
    const expires_at = response.data.expires_at;

    state.refresh_token = refresh_token;
    state.status = 'success'
    state.token = `Bearer ${token}`
    state.expires_at = expires_at
  },
  [AUTH_ERROR]: state => {
    state.status = 'error'
  },
  [AUTH_LOGOUT]: state => {
    state.token = ''
    state.refresh_token = ''
    state.status = ''
    state.expires_at = ''
  },
  [AUTH_REFRESH]: (state, data) => {
    state.token = `Bearer ${data.token}`
    state.refresh_token = data.refresh_token
    state.expires_at = data.expires_at
  },
  [AUTH_SET_TOKEN]: (state, token) => {
    state.token = `Bearer ${token}`
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
