<template>
    <div>
        <div class="relative ">

            <div class="flex flex-col w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                <div class="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
                    {{ this.structure.name }}
                </div>
                <div class="mt-8">
                    <form autoComplete="off">
                        <h1>{{ this.structure.schema.title.name }}</h1>
                        {{ this.structure.schema.title.description }}
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <input
                                    type="text"
                                    class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    v-model="title"
                                    :placeholder="this.structure.schema.title.name"
                                />
                            </div>
                        </div>

                        <h1>{{ this.structure.schema.sub_title.name }}</h1>
                        {{ this.structure.schema.sub_title.description }}
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <input
                                    type="text"
                                    class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    v-model="sub_title"
                                    :placeholder="this.structure.schema.sub_title.name"
                                />
                            </div>
                        </div>

                        <h1>{{ this.structure.schema.background_image.name }}</h1>
                        {{ this.structure.schema.background_image.description }}
                        <img
                            v-if="background_image"
                            :src="background_image"
                            class="max-w-2xl"
                            alt="Hero Image"
                        >
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <button
                                    type="button"
                                    class="px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                    @click="click1">
                                    <i class="fas fa-upload"></i> Upload File
                                </button>
                                <button
                                    type="button"
                                    class="px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                    @click="remove_file">
                                    <i class="fas fa-times"></i> Remove File
                                </button>
                                <input
                                    type="file"
                                    ref="input1"
                                    style="display: none"
                                    @change="previewImage" accept="image/*">    
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import firebase from 'firebase';

export default {
    props: {
        location: {
            type: String,
            required: true,
        },
        structure: {
            type: Object,
            default() {
                return {}
            },
            required: true
        },
        content: {
            type: Object,
            default() {
                return {}
            },
            required: true
        }
    },

    mounted () {
        if (this.content.data) {
            this.form = this.content.data
        } else {
            this.form = {
                title: "",
                sub_title: "",
                background_image: "",
            }
            this.$store.dispatch('content/updateBlock', {location: this.location, data: this.form})
        }
    },

    data () {
        return {
            form: {},
            caption : '',
            img1: '',
            imageData: null
        }
    },

    methods: {
        click1() {
            this.$refs.input1.click()   
        },

        previewImage(event) {
            this.uploadValue = 0;
            this.img1 = null;
            this.imageData = event.target.files[0];
            this.onUpload()
        },

        onUpload(){
            this.img1 = null;
            const storageRef = firebase.storage().ref(`${this.imageData.name}`).put(this.imageData);
            storageRef.on(`state_changed`, snapshot => {
                this.uploadValue = (snapshot.bytesTransferred/snapshot.totalBytes)*100;
            },
            error => {
                console.error(error.message)
            },
            () => {
                this.uploadValue=100;
                storageRef.snapshot.ref.getDownloadURL().then((url) => {
                    this.img1 = url;
                    this.form.background_image = url;
                    this.$store.commit('content/setBlockData', {location: this.location, input: 'background_image', value: url})
                });
            });
        },

        remove_file(){
            this.img1 = null;
            this.form.background_image = null;
            this.$store.commit('content/setBlockData', {location: this.location, input: 'background_image', value: null})
        }
    },

    computed: {
        title: {
            get () {
                const location_key = this.location.split(',');
                return this.$store.state.content.locations[location_key[0]][location_key[1]].data.title
            },
            set (value) {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'title', value: value})
            }
        },
        sub_title: {
            get () {
                const location_key = this.location.split(',');
                return this.$store.state.content.locations[location_key[0]][location_key[1]].data.sub_title
            },
            set (value) {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'sub_title', value: value})
            }
        },
        background_image: {
            get () {
                const location_key = this.location.split(',');
                return this.$store.state.content.locations[location_key[0]][location_key[1]].data.background_image
            },
            set (value) {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'background_image', value: value})
            }
        }
    }
}
</script>