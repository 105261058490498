<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
        <div>
        <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Museo">
        <h2 class="mt-6 mb-3 text-center text-3xl font-extrabold text-gray-900">
            Register new Museo account
        </h2>


        <div v-if="error" class="bg-red-200 border-red-600 text-red-600 border-l-4 p-4" role="alert">
            <p class="font-bold">
                Error
            </p>
            <p>
                {{ error.message }}
            </p>
        </div>


        </div>
        <form class="mt-8 space-y-6" id="login-form" @submit="login" v-on:submit.prevent>
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
            <div>
            <label for="user-name" class="sr-only">Name</label>
            <input id="user-name" name="email" type="text" v-model="register_form.user.name" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Name">
            </div>
        </div>

        <div class="rounded-md shadow-sm -space-y-px">
            <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" name="email" type="email" autocomplete="email" v-model="register_form.user.email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
            </div>
            <div>
            <label for="password" class="sr-only">Password</label>
            <input id="password" name="password" type="password" autocomplete="current-password" v-model="register_form.user.password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
            </div>
        </div>

        <div class="flex items-center justify-between">
            <div class="text-sm">
            <a href="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                Back to login
            </a>
            </div>
        </div>

        <div>
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
            </span>
            Register
            </button>
        </div>
        </form>
    </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            register_form: {
                user: {
                    name: "",
                    email: "",
                    password: ""
                }
            },
            error: null,
        }
    },

    methods: {
        login () {
            fetch(`${process.env.VUE_APP_API_ROUTE}auth/register`, {
                method: 'POST',
                mode: 'cors', // no-cors, *cors, same-origin
                cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                credentials: 'same-origin', // include, *same-origin, omit
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow', // manual, *follow, error
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(this.register_form)
            })
            .then(response => {
                if (!response.ok) { throw response }
                response.json()
            })
            .then(data => {
                console.log(data)
            })
            .catch(err => {
                err.json()
                    .then(error => {
                        console.log(error);
                        this.error = error;
                    })
            })
        }
    }
}
</script>