<template>
    <div>
        <div class="relative ">

            <div class="flex flex-col w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                <div class="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
                    {{ this.structure.name }}
                </div>
                <div class="mt-8">
                    <form autoComplete="off">
                        <h1>{{ this.structure.schema.text.name }}</h1>
                        {{ this.structure.schema.text.description }}
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <textarea
                                    type="text"
                                    class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    v-model="text"
                                    :placeholder="this.structure.schema.text.name"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    props: {
        location: {
            type: String,
            required: true,
        },
        structure: {
            type: Object,
            default() {
                return {}
            },
            required: true
        },
        content: {
            type: Object,
            default() {
                return {}
            },
            required: true
        }
    },

    mounted () {
        if (this.content.data) {
            this.form = this.content.data
        } else {
            this.form = {
                text: "",
            }
            this.$store.dispatch('content/updateBlock', {location: this.location, data: this.form})
        }
    },

    data () {
        return {
            form: {},
        }
    },

    methods: {
    },

    computed: {
        text: {
            get () {
                const location_key = this.location.split(',');
                return this.$store.state.content.locations[location_key[0]][location_key[1]].data.text
            },
            set (value) {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'text', value: value})
            }
        }
    }
}
</script>