import Vue from 'vue';
import App from './App.vue';
import router from './router';

// Vuex
import store from './store';

// Firebase
import firebase from "firebase";

// Third Party Plugins
import VCalendar from "v-calendar";
import VModal from 'vue-js-modal';
import Notifications from 'vue-notification'


// Tailwind
import './assets/styles/index.css';

Vue.config.productionTip = false

Vue.use(VCalendar, {});
Vue.use(VModal);
Vue.use(Notifications);

new Vue({
  render: h => h(App),
  router,
  store,
  created () {
    const firebaseConfig = {
      apiKey: "AIzaSyA7KKu78KEMkf029B-Thy5dEi6A12h7tJ4",
      authDomain: "museo-cms.firebaseapp.com",
      projectId: "museo-cms",
      storageBucket: "museo-cms.appspot.com",
      messagingSenderId: "1074914330825",
      appId: "1:1074914330825:web:db94512fb9b9943a0c6518",
      measurementId: "G-PGLWBCWMFX"
  }
  
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();
  }
}).$mount('#app')
