// initial state
// shape: [{ id, quantity }]
const state = () => ({
    exhibit: {},
    schedule: [],
    updated: false
  })
  
  // getters
  const getters = {
    artefact: (state) => {
        return state.artefact;
    },

    schedule_items: (state) => {
      return state.schedule;
    },

    formatted_schedule_items: (state) => {
      return state.schedule;
    },
  }
  
  // actions
  const actions = {
    set ({ commit }, exhibit) {
      commit('resetUpdated')
      commit('setExhibit', { exhibit })
    },

    set_artefact ({commit}, artefact) {
      commit('setArtefact', {artefact});
    },

    update ({ commit }, {input, value}) {
      commit('setExhibitData', { input, value })
    },

    update_schedule({ commit }, schedule) {
      commit('updateSchedule', { schedule });
    },

    add_schedule_item ({commit}, schedule_item) {
      commit('addScheduleItem', { schedule_item });
    },

    remove_schedule_item ({commit}, schedule_item) {
      commit('removeScheduleItem', { schedule_item });
    },

    clear_schedule ({commit}) {
      commit('clearSchedule');
    }
  
    //   updateExhibit ({ commit }, {location, data}) {
    //       commit('setExhibit', {location, data})
    //   },
  }
  
  // mutations
  const mutations = {
  
    setExhibit (state, { exhibit }) {
      state.exhibit = exhibit
      state.schedule = exhibit.schedule.items ? exhibit.schedule.items : []
    },

    setArtefact (state, { artefact }) {
      state.exhibit.artefact = artefact;
      state.updated = true;
    },
  
    setExhibitData (state, { input, value }) {
        state[input] = value

        state.updated = true;
    },
  
    setLocations (state, { locations }) {
        state.locations = locations;
    },

    updateSchedule (state, { schedule}) {
      state.schedule = schedule;
      state.updated = true;

    },

    addScheduleItem (state, { schedule_item }) {
      const colors = ["gray", "red", "orange", "yellow", "green", "teal", "blue", "indigo", "purple", "pink"];
      let selected_color = "blue";
      for (var i = 0; i < state.schedule.length; i++) {
        selected_color = colors[i % colors.length];
      }

      const item = {
        key: schedule_item.range.start.getTime(),
        dates: {
          start: schedule_item.range.start.toString(),
          end: schedule_item.range.end.toString()
        },
        start_date: schedule_item.range.start.toString(),
        end_date: schedule_item.range.end.toString(),
        highlight: selected_color,
        description: schedule_item.artefact.title,
        artefact_id: schedule_item.artefact.id,
        customData: {
          artefact_id: schedule_item.artefact_id,
        },
      }

      // Search array for existing item
      const index = state.schedule.findIndex((scheduled_item => scheduled_item.key === item.key));

      if (index > 0) {
        state.schedule[index] = item
      } else {
        // add new item
        state.schedule.push(item)
      }

      state.updated = true;
    },

    removeScheduleItem (state, { schedule_item }) {
      const item = {
        key: schedule_item.key,
      }

      let item_index = null;

      state.schedule.forEach((sched, index) => {
        if (sched.key == item.key) {
          item_index = index;
        }
      })

      if (item_index !== null) {
        state.schedule.splice(item_index, 1);
      }

      state.updated = true;
    },

    clearSchedule (state) {
      state.schedule = [];
      state.updated = true;
    },
  
    resetUpdated (state) {
        state.updated = false;
    },
  }
  
  export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
  }