import axios from 'axios'
import store from '../store'
import { AUTH_REFRESH, AUTH_LOGOUT } from '../store/actions/auth'

const INSTANCE = axios.create({
  baseURL: process.env.VUE_APP_API_ROUTE,
  timeout: 300000
})

INSTANCE.interceptors.request.use(
  config => {
    const token = store.state.auth.token

    if (token !== null) {
      config.headers.Authorization = token
      return config
    } else {
      return config
    }
  },
  err => Promise.reject(err)
)

INSTANCE.interceptors.response.use(
  response => {
    const { authorization } = response.headers

    if (!store.getters.isAuthenticated) {
      localStorage.clear()
      return response
    }

    if (authorization) {
      store.dispatch(AUTH_REFRESH, authorization)
    }

    return response
  },
  error => {
    const { status, data } = error.response
    let errorObject = {};

    if (!status) {
      errorObject = {
        message: 'There was an issue with your request. Please try again later.',
        status: 500
      }
      throw errorObject
    }

    if (status === 400) {
      console.log('400!?: ', error.response)
    }

    if (status === 401) {
      console.log('STATUS: REST RESOURCE: ', error.response)
      errorObject = {
        message: data.error,
        status: status
      }
      store.dispatch(AUTH_LOGOUT)
    }

    if (status === 403) {
      console.log('STATUS: REST RESOURCE: ', error.response)
      errorObject = {
        message: data,
        status: status
      }
      store.dispatch(AUTH_LOGOUT)
    }

    if (status === 404) {
      errorObject = {
        message: data.message,
        status: status
      }
      throw errorObject
    }

    if (data.error === 'The token has been blacklisted') {
      errorObject = {
        message: 'Your token has been blacklisted, please login again.',
        status: status
      }
      store.dispatch(AUTH_LOGOUT)
      throw errorObject
    }

    if (status) {
      console.log('STATUS: REST RESOURCE: ', error.response)
      errorObject = {
        message: data,
        status: status
      }
    }

    throw errorObject
  }
)

async function refresh_token () {
  return new Promise((resolve, reject) => {
    const date = Date.parse(store.state.auth.expires_at);
    const now = new Date();

    if (date >= now) {
      fetch (`${process.env.VUE_APP_API_ROUTE}auth/refresh?refresh_token=${store.state.auth.refresh_token}`)
        .then(response => response.json())
        .then(data => {
          store.dispatch(AUTH_REFRESH, data)
          resolve();
        })
        .catch(() => {
          store.dispatch(AUTH_LOGOUT)
          reject();
        })
    } else {
      resolve();
    }
  });
}

export default class RestResource {
  async POSTRequest (endpoint, payload) {
    await refresh_token();
    return await INSTANCE.post(endpoint, payload)
  }

  async GETRequest (endpoint) {
    await refresh_token();
    return await INSTANCE.get(endpoint)
  }

  async PUTRequest (endpoint, payload) {
    await refresh_token();
    return await INSTANCE.put(endpoint, payload)
  }
  async PATCHRequest (endpoint, payload) {
    await refresh_token();
    return await INSTANCE.patch(endpoint, payload)
  }

  async DELETERequest (endpoint) {
    await refresh_token();
    return await INSTANCE.delete(endpoint)
  }
}