<template>
  <div id="app">
    <notifications group="foo" position="top right"/>
   <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'app',
}
</script>