/* eslint-disable */
import router from '../../router'
import {
  CHANGE_ACTIVE_ORGANISATION,
  CHANGE_ACTIVE_ORGANISATION_SUCCESS,
  GET_SUBORGANISATIONS,
  GET_SUBORGANISATIONS_SUCCESS,
  USER_SAVE,
  ACTIVE_ORGANISATION_SAVE
} from '../actions/user'
import RestResource from '@/services/RestResource'
import { USER_SIGNIN } from '@/main'

const state = {
  user: {},
  // activeOrganisation: {},
  // subOrganisations: [],
  // subOrganisationsReady: false
}

const getters = {
  getUser: state => state.user,
  // getSubOrganisations: state => state.subOrganisations,
  // getSubOrganisationsReady: state => state.subOrganisationsReady,
  // activeOrganisation: state => state.activeOrganisation,
  user: (state) => {
    return state.user
  }
}

const actions = {
  [USER_SAVE]: async ({ commit, dispatch }, user) => {
    // dispatch(GET_SUBORGANISATIONS, { user, organisation: user.organisation })
    commit(USER_SAVE, { ...user });
    return user;
  },
  [ACTIVE_ORGANISATION_SAVE]: async ({ commit, dispatch }, organisation) => {
    return organisation
  },
  [CHANGE_ACTIVE_ORGANISATION]: async ({ commit, dispatch }, data) => {
    const payload = { organisation: data.organisation.uuid }
    const restResourceService = new RestResource()

    if (data.organisation.uuid !== data.userOrganisation.uuid) {
      try {
        await restResourceService.PUTRequest(`organisation/${data.organisation.uuid}/remote-admin`, data.organisation)
        dispatch(GET_SUBORGANISATIONS, { activeOrganisation: data.organisation })
      } catch (error) {
        console.error('error', error)
      }
    } else {
      dispatch(GET_SUBORGANISATIONS, { activeOrganisation: data.organisation })
    }
  },
  [GET_SUBORGANISATIONS]: async ({ commit, dispatch }, data) => {
    const { user, activeOrganisation, organisation } = data
    const restResourceService = new RestResource()
    let requestURL

    if (activeOrganisation) {
      requestURL = `organisation/${activeOrganisation.uuid}/organisations`
    } else {
      requestURL = `organisation/${organisation.uuid}/organisations`
    }

    try {
      const { data } = await restResourceService.GETRequest(requestURL)
      USER_SIGNIN.$emit('clientLoaded', false)
      commit(GET_SUBORGANISATIONS_SUCCESS, data.sub)

      if (activeOrganisation) {
        commit(CHANGE_ACTIVE_ORGANISATION_SUCCESS, activeOrganisation)
      }

      if (user) {
        commit(USER_SAVE, user)
        router.push('/')
        // router.push('/locations')
      }
    } catch (error) {
      console.error('error', error)
    }
  }
}

const mutations = {
  [USER_SAVE]: (state, user) => {
    state.profile = { ...user }
    // state.activeOrganisation = user.organisation
    state.user = { ...user }
  },
  [CHANGE_ACTIVE_ORGANISATION_SUCCESS]: (state, organisation) => {
    state.activeOrganisation = organisation
  },
  [GET_SUBORGANISATIONS_SUCCESS]: (state, subOrganisations) => {
    state.subOrganisations = subOrganisations
    state.subOrganisationsReady = true
  }
}

export default {
  state,
  getters,
  actions,
  mutations
}
