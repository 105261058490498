<template>
    <div>
        <div class="w-full mb-4 shadow-lg bg-white dark:bg-gray-700 items-right h-16 rounded-2xl z-40">
            <div class="p-4 text-right z-20 flex flex-row justify-end h-full flex-end">  
                <Button
                  @click.native="update_exhibit()"
                  :disabled="!updated"
                  btntype="primary"
                  :loading="loading">
                  <i class="fas fa-save"></i> Save
                </Button>

                <Button
                  @click.native="select_publish()"
                  :disabled="!selected_artefact"
                  btntype="primary"
                  :loading="loading">
                  <i class="fas fa-star"></i> Publish
                </Button>

            </div>
        </div>
        <div class="flex mx-4">
            <section
                id="qr_section"
                class="shadow border bg-white dark:bg-gray-800 rounded-md flex items-center p-4 mr-2">
                <canvas id="qr"></canvas>
                <img src="" alt="" id="image" />
            </section>

            <section
                id="artefact_section"
                class="shadow border bg-white dark:bg-gray-800 rounded-md flex flex-1 flex-col items-left p-4 ml-2">
                <!-- Artefact Selection -->
                <div class="p-4">
                    <p class="text-2xl text-gray-800 dark:text-white">
                        Primary Artefact:
                    </p>
                    <p class="text-md text-gray-500 dark:text-gray-400 py-4 font-light">
                      Your Primary Artefact is shown by default. When Scheduling Artefacts for an Exhibit, the primary Artefact will be shown if nothing else is found for the current timeslot.
                    </p>
                    <div
                      v-if="selected_artefact"
                      class="border-gray-400 flex flex-row mb-2"
                      @click="show_artefacts()"
                    >
                      <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                        <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                          <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Museo">
                        </div>
                        <div class="flex-1 pl-1 md:mr-16">
                          <div class="font-medium dark:text-white">
                            {{ selected_artefact.title }}
                          </div>
                          <div class="text-gray-600 dark:text-gray-200 text-sm">
                            {{ selected_artefact.description }}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div v-else>
                      <button
                          type="button"
                          class="p-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                          @click="show_artefacts()">
                          Select Artefact
                      </button>
                    </div>

                    <modal
                        classes="bg-white dark:bg-gray-800 rounded-md p-4"
                        height="auto"
                        name="artefact_select_modal">
                        <section v-if="artefacts">
                            <p class="text-2xl text-gray-800 dark:text-white">
                                Available Artefacts:
                            </p>
                            <p class="text-md text-gray-500 dark:text-gray-400 py-4 font-light">
                                Select the artefact you wish to link to this exhibit.
                            </p>
                            <div class="container flex flex-col mx-auto w-full h-auto max-h-60 items-center justify-center">
                                <ul class="flex flex-col overflow-y-scroll">
                                    <li
                                      class="flex flex-row mb-2 rounded-md"
                                      :class="selected_artefact && artefact.id == selected_artefact.id ? 'border-blue-700 border-4' : 'border-gray-400'"
                                      v-for="artefact in artefacts" :key="artefact.id"
                                      @click="select_artefact(artefact)"
                                    >
                                      <div
                                        class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                                        <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                                          <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Museo">
                                        </div>
                                        <div class="flex-1 pl-1 md:mr-16">
                                          <div class="font-medium dark:text-white">
                                            {{ artefact.title }}
                                          </div>
                                          <div class="text-gray-600 dark:text-gray-200 text-sm">
                                            {{ artefact.description }}
                                          </div>
                                          <div class="text-gray-600 dark:text-gray-200 text-xs">
                                            updated: {{ new Date(artefact.updated_at) }}
                                          </div>
                                        </div>
                                      </div>
                                    </li>
                                </ul>
                            </div>
                        </section>
                    </modal>
                </div>

                <!-- Published -->
                <div class="p-4 flex flex-row items-center">
                    <p class="text-2xl text-gray-800 dark:text-white">
                        Last Published: 
                    </p>
                    <p class="ml-4 text-gray-600 dark:text-gray-200 text-sm">
                        {{ exhibit.published_at ? new Date(exhibit.published_at) : "This exhibit has not been published yet."}}
                    </p>
                </div>
            </section>
        </div>

        <!-- Schedule -->
        <Schedule :artefacts="artefacts"/>


        <modal
            classes="bg-white dark:bg-gray-800 rounded-md p-4"
            height="auto"
            :clickToClose="false"
            name="publish_modal">
            Are you sure you want to publish?
            Any changes you've made to your Exhibit, and its linked Artefact will become publicly visible.

            <div class="py-4 px-10 flex mx-auto flex-row justify-between">
              <button
                @click="publish_exhibit()"
                type="button"
                class="py-2 px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                Publish
              </button>
              <button
                @click="$modal.hide('publish_modal')"
                type="button"
                class="py-2 px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                Cancel
              </button>
            </div>
        </modal>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import RestResource from '@/services/RestResource';
import QRCode from "qrcode-with-logos";
import Schedule from "@/components/Exhibit/Schedule";
import Button from "@/components/Global/Button";
export default {
    components: {
      Schedule,
      Button
    },

    data () {
        return {
          exhibit: {},
          selected_artefact: null,
          artefacts: {},
          updated: false,
          loading: false
        }
    },

    mounted () {
      this.get_exhibit(this.$route.params.id);
    },

    methods: {
        async get_exhibit (id) {
            const restResouceService = new RestResource();
            const { data } = await restResouceService.GETRequest(`exhibits/${id}`);
            this.$store.dispatch('exhibit/set', data.data);
            this.exhibit = data.data;

            // selected artefact
            if (this.exhibit.artefact.id) {
                this.selected_artefact = this.exhibit.artefact;
            }

            this.$store.dispatch('exhibit/set', data.data);

            this.generate_qr();

            // Get Artefacts once loaded
            this.get_artefacts();
        },

        async get_artefacts () {
          const restResouceService = new RestResource();
          const { data } = await restResouceService.GETRequest('artefacts')
          this.artefacts = data.data;


          if (this.exhibit.artefact.id) {
            this.artefacts.forEach(artefact => {
              if (this.exhibit.artefact.id === artefact.id) {
                if (artefact.updated_at !== this.exhibit.artefact.updated_at) {
                  this.select_artefact(artefact);

                  this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: `Artefact ${artefact.title} has new updates.`,
                    text: 'It has been automatically assigned to your Exhibit. Save now to confirm the changes.'
                  });
                }
              }
            })
          }
        },

        async update_exhibit () {
          try {
            this.loading = true;

            const content = {
              artefact: this.selected_artefact,
              schedule: this.schedule_items
            };

            const restResouceService = new RestResource();
            const { data } = await restResouceService.PATCHRequest(`exhibits/${this.exhibit.id}`, content);
            this.exhibit = data.data;

            // selected artefact
            if (this.exhibit.artefact.id) {
                this.selected_artefact = this.exhibit.artefact;
            }
            this.$store.dispatch('exhibit/set', data.data);

            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Exhibit Updated ',
              text: 'Successfully updated exhibit.'
            });

            this.loading = false;
          } catch (err) {
              console.error(err);
              this.loading = false;
              this.$notify({
                  group: 'foo',
                  type: 'error',
                  title: 'Error',
                  text: err.message
              });
          }
        },

        async publish_exhibit () {
          try {
            this.loading = true;

            const restResouceService = new RestResource();
            const { data } = await restResouceService.POSTRequest(`exhibits/publish/${this.exhibit.id}`);
            this.exhibit = data.data;

            // selected artefact
            if (this.exhibit.artefact.id) {
                this.selected_artefact = this.exhibit.artefact;
            }
            this.$store.dispatch('exhibit/set', data.data);
            this.$modal.hide('publish_modal')

            this.$notify({
              group: 'foo',
              type: 'success',
              title: 'Exhibit Published ',
              text: 'Successfully published exhibit.'
            });

            this.loading = false;
          } catch (err) {
              console.error(err);
              this.loading = false;
              this.$notify({
                  group: 'foo',
                  type: 'error',
                  title: 'Error',
                  text: err.message
              });
          }
        },

        show_artefacts () {
            this.$modal.show('artefact_select_modal')
        },

        select_artefact (artefact) {
            this.$modal.hide('artefact_select_modal');
            this.selected_artefact = artefact;
            this.$store.dispatch('exhibit/set_artefact', artefact);
        },

        select_publish () {
          this.$modal.show('publish_modal')
        },

        generate_qr () {
            const qr_code = new QRCode({
                canvas: document.getElementById("qr"),
                content: this.exhibit.url,
                width: 380,
                nodeQrCodeOptions: {
                    color: {
                        dark: '#CEA00D',
                        light: '#FFFFFF'
                    },
                },
                //   download: true,
                image: document.getElementById("image"),
                logo: {
                    src: require("@/assets/logo-192x192.png"),
                    borderSize: 0.05,
                } 
            });

            qr_code.toCanvas()
            .then(() => {
            })
        }
    },

    watch: {
      '$store.state.exhibit.updated': function (new_value) {
          this.updated = new_value;
      }
    },
    
    computed: {
      ...mapGetters({
        schedule_items: 'exhibit/formatted_schedule_items'
      })
    }
}
</script>