<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
        <div>
        <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Workflow">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Forgot my password
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
            Don't worry, it happens to the best of us. Just enter the email you used to register and we will send you a link to reset your password.
        </p>
        </div>
        <form class="mt-8 space-y-6" id="login-form" @submit="login" v-on:submit.prevent>
        <input type="hidden" name="remember" value="true">
        <div class="rounded-md shadow-sm -space-y-px">
            <div>
            <label for="email-address" class="sr-only">Email address</label>
            <input id="email-address" name="email" type="email" autocomplete="email" v-model="login_form.email" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Email address">
            </div>
        </div>

        <div class="flex items-center justify-between">
            <div class="text-sm">
            <a href="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                Back to login
            </a>
            </div>
        </div>

        <div>
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
            </span>
            Reset Password
            </button>
        </div>
        </form>
    </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            login_form: {
                email: ""
            }
        }
    },

    methods: {
        login () {
            fetch(`${process.env.VUE_APP_API_ROUTE}auth/reset-password`, {
                method: 'POST',
                mode: 'cors',
                cache: 'no-cache',
                credentials: 'same-origin',
                headers: {
                    'Content-Type': 'application/json'
                },
                redirect: 'follow',
                referrerPolicy: 'no-referrer',
                body: JSON.stringify(this.login_form)
            })
            .then(response => response.json())
            .then(data => {
                data.token
            })
            .catch(err => {
                console.error(err);
            })
        }
    }
}
</script>