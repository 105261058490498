<template>
    <div>
        <div v-for="(row, row_index) in form" :key="row_index">
            <div v-for="(col, col_index) in row" :key="col_index">
                <component
                    class="my-4"
                    :is="set_component(`${row_index},${col_index}`)"
                    :structure="set_structure(`${row_index},${col_index}`)"
                    :content="set_content(`${row_index},${col_index}`)"
                    :location="`${row_index},${col_index}`"
                />
            </div>
        </div>
    </div>

</template>

<script>
import component from 'vue';
import Hero from './Hero.vue';
import Paragraph from './Paragraph.vue';
import Media from './Media.vue';
// import File from './File';
export default {
    props: {
        component: component,
        template: {
            type: Object,
            default() {
                return {}
            },
            required: true
        },
        content: {
            type: Object,
            default() {
                return {}
            },
            required: true
        }
    },

    data () {
        return {
            updated: false,
            form: {
                cunt: ""
            },
        }
    },

    methods: {
        sort_form () {
            const temp_form = {};

            for (let x = 0; x < Object.keys(this.content).length; x++) {
                let key = x + 1;
                temp_form[`r_${key}`] = this.content[`r_${key}`];
            }

            this.form = temp_form;
        },

        set_component (location) {
            const location_keys = location.split(',');

            let structure = this.template.structure[location_keys[0]][location_keys[1]];
            if (!structure) {
                // This is a shack
                structure = this.template.structure[location_keys[0]++][location_keys[1]];
            }

            switch (structure.name) {
                case 'Hero':
                    return Hero;
                case 'Paragraph':
                    return Paragraph;
                case 'Media':
                    return Media;
            }
        },

        set_structure (location) {
            const location_keys = location.split(',');
            const structure = this.template.structure[location_keys[0]][location_keys[1]];
            return structure;
        },

        set_content (location) {
            const location_keys = location.split(',');
            return this.content[location_keys[0]][location_keys[1]];
        },
    },

    watch: {
        content: function () {
            this.sort_form();
        }
    }
    
}
</script>