<template>
    <main class="bg-gray-100 dark:bg-gray-800 rounded-2xl relative h-screen overflow-hidden relative">
        <div class="flex items-start justify-between">

            <Navigation />

            <div class="flex flex-col w-full pl-0 md:p-4 md:space-y-4">
                <Header />
                <div class="overflow-auto h-screen pb-24 pt-2 pr-2 pl-2 md:pt-0 md:pr-0 md:pl-0">
                    <div class="route-container">
                        <router-view></router-view>
                    </div>
                </div>
            </div>
        </div>
    </main>
</template>

<script>
import Navigation from '@/layouts/Navigation'
import Header from '@/layouts/Header'
export default {
    components: {
        Navigation,
        Header
    }
}
</script>