import Vue from 'vue'
import Vuex from 'vuex'
import exhibit from './modules/exhibit'
import content from './modules/content'
import auth from './modules/auth'
import user from './modules/user'
import createPersistedState from 'vuex-persistedstate'

// import products from './modules/products'
// import createLogger from '../plugins/logger'

Vue.use(Vuex)

const debug = process.env.NODE_ENV !== 'production'

export default new Vuex.Store({
  plugins: process.browser ? [createPersistedState({
    reducer: ({ auth, user }) => ({
      auth,
      user
    })
  })] : [],
  modules: {
    exhibit,
    content,
    auth,
    user,
  },
  strict: debug,
})