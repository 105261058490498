<template>
    <div>
        <div class="container flex flex-col mx-auto w-full items-center justify-center">
            <ul class="flex flex-col">
                <li class="border-gray-400 flex flex-row mb-2" v-for="exhibit in exhibits" :key="exhibit.id">
                    <div class="shadow border select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                        <router-link :to="'/exhibits/' + exhibit.id" class="select-none cursor-pointer bg-white dark:bg-gray-800 rounded-md flex flex-1 items-center p-4">
                            <div class="flex flex-col w-10 h-10 justify-center items-center mr-4">
                                <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Museo">
                            </div>
                            <div class="flex-1 pl-1 md:mr-16">
                                <div>
                                    <div class="text-gray-600 dark:text-gray-200 text-sm">
                                        Exhibit: {{ exhibit.id }}
                                    </div>
                                    <div class="font-medium dark:text-white">
                                        Primary Artefact: {{ exhibit.artefact.title ? exhibit.artefact.title : "Artefact Not Set" }}
                                    </div>
                                </div>
                                <div class="text-gray-600 dark:text-gray-200 text-sm">
                                    {{ exhibit.artefact.name }}
                                </div>
                            </div>
                            <div class="text-gray-600 dark:text-gray-200 text-xs">
                                updated: {{ new Date(exhibit.updated_at) }}
                            </div>
                        </router-link>
                        <a
                            :href="exhibit.url"
                            target="_blank"
                            class="px-4 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-blue-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                                <i class="fas fa-eye"></i>
                        </a>
                    </div>
                </li>
            </ul>
        </div>

    </div>
</template>

<script>
import RestResource from '@/services/RestResource';
export default {
    data () {
        return {
            exhibits: []
        }
    },

    created () {
        this.get_exhibits()
    },
    
    methods: {
        async get_exhibits () {
            const restResouceService = new RestResource();
            const { data } = await restResouceService.GETRequest('exhibits')
            this.exhibits = data.data;
        }
    }
}
</script>