/**
 * Auth
 * 
 * 
 * @param {*} to 
 * @param {*} from 
 * @param {*} next 
 */
 import store from '../store'

function auth (to, from, next) {
  if (!store.getters.isAuthenticated) {
    next('/login')
  } else {
    next()
  }
}

export default auth;