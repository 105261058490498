// initial state
// shape: [{ id, quantity }]
const state = () => ({
  title: "",
  description: "",
  locations: null,
  updated: false
})

// getters
const getters = {
    locations: (state) => {
        return state.locations;
    },
//   cartProducts: (state, getters, rootState) => {
//     return state.items.map(({ id, quantity }) => {
//       const product = rootState.products.all.find(product => product.id === id)
//       return {
//         title: product.title,
//         price: product.price,
//         quantity
//       }
//     })
//   },

//   cartTotalPrice: (state, getters) => {
//     return getters.cartProducts.reduce((total, product) => {
//       return total + product.price * product.quantity
//     }, 0)
//   }
}

// actions
const actions = {
    set ({ commit }, locations) {
        commit('resetUpdated')
        commit('setLocations', { locations })
    },

    updateBlock ({ commit }, {location, data}) {
        commit('setBlock', {location, data})
    },
//   checkout ({ commit, state }, products) {
//     const savedCartItems = [...state.items]
//     commit('setCheckoutStatus', null)
//     // empty cart
//     commit('setCartItems', { items: [] })
//     shop.buyProducts(
//       products,
//       () => commit('setCheckoutStatus', 'successful'),
//       () => {
//         commit('setCheckoutStatus', 'failed')
//         // rollback to the cart saved before sending the request
//         commit('setCartItems', { items: savedCartItems })
//       }
//     )
//   },

//   addProductToCart ({ state, commit }, product) {
//     commit('setCheckoutStatus', null)
//     if (product.inventory > 0) {
//       const cartItem = state.items.find(item => item.id === product.id)
//       if (!cartItem) {
//         commit('pushProductToCart', { id: product.id })
//       } else {
//         commit('incrementItemQuantity', cartItem)
//       }
//       // remove 1 item from stock
//       commit('products/decrementProductInventory', { id: product.id }, { root: true })
//     }
//   }
}

// mutations
const mutations = {
//   pushProductToCart (state, { id }) {
//     state.items.push({
//       id,
//       quantity: 1
//     })
//   },

//   incrementItemQuantity (state, { id }) {
//     const cartItem = state.items.find(item => item.id === id)
//     cartItem.quantity++
//   },

    setBlock (state, { location, data }) {
        const location_key = location.split(',');
        const block = state.locations[location_key[0]][location_key[1]];

        block.data = data;
    },

    setBlockData (state, { location, input, value }) {
        const location_key = location.split(',');
        const block = state.locations[location_key[0]][location_key[1]];

        block.data[input] = value ? value : "";

        state.updated = true;
    },

    setLocations (state, { locations }) {
        state.locations = locations;
    },

    resetUpdated (state) {
        state.updated = false;
    },

//   setCheckoutStatus (state, status) {
//     state.checkoutStatus = status
//   }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}