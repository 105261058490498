<template>
    <div>
        <div class="relative ">

            <div class="flex flex-col w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                <div class="self-center mb-6 text-xl font-light text-gray-600 sm:text-2xl dark:text-white">
                    {{ this.structure.name }}
                </div>
                <div class="mt-8">
                    <form autoComplete="off" onsubmit="return false;">
                        <h1>{{ this.structure.schema.embed_url.name }}</h1>
                        {{ this.structure.schema.embed_url.description }}
                        <div v-if="embed_input">
                            <iframe
                                :src="embed_url.url"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                                class="w-full mx-auto md:h-96 sm:h-auto"
                            >
                            </iframe>
                        </div>
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <input
                                    type="text"
                                    class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    v-model="embed_input"
                                    :placeholder="this.structure.schema.embed_url.name"
                                />
                            </div>
                        </div>

                        <h1>{{ this.structure.schema.media.name }}</h1>
                        {{ this.structure.schema.media.description }}
                        <div v-if="media">
                            <img
                                v-if="media.type === 'image'"
                                :src="media.url"
                                class="max-w-2xl"
                                alt="Hero Image"
                            >
                            <audio
                                v-if="media.type === 'audio'"
                                controls>
                                <source :src="media.url">
                                Your browser does not support the audio element.
                            </audio>
                        </div>
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <Button
                                    @click.native="click1"
                                    btntype="primary"
                                    :loading="loading">
                                    <i class="fas fa-upload"></i> Upload File
                                </Button>
                                <button
                                    type="button"
                                    :disabled="!media.url"
                                    class="px-4 py-2 disabled:opacity-50 disabled:cursor-not-allowed mx-3 bg-red-600 hover:bg-red-700 focus:ring-red-500 focus:ring-offset-red-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg"
                                    @click="remove_file">
                                    <i class="fas fa-times"></i> Remove File
                                </button>
                                <input
                                    type="file"
                                    ref="input1"
                                    style="display: none"
                                    @change="previewImage"
                                    accept="image/*, audio/*">    
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import firebase from 'firebase';
import Button from '@/components/Global/Button';

export default {
    props: {
        location: {
            type: String,
            required: true,
        },
        structure: {
            type: Object,
            default() {
                return {}
            },
            required: true
        },
        content: {
            type: Object,
            default() {
                return {}
            },
            required: true
        }
    },

    components: {
        Button
    },

    mounted () {
        if (this.content.data) {
            this.form = this.content.data
            console.log("form", this.form);
            this.embed_input = this.form.embed_url.url
        } else {
            this.form = {
                media: {
                    type: "",
                    url: ""
                },
                embed_url: {
                    type: "",
                    url: ""
                },
            }
            this.$store.dispatch('content/updateBlock', {location: this.location, data: this.form})
        }
    },

    data () {
        return {
            form: {},
            caption : '',
            img1: '',
            embed_input: '',
            file_type: null,
            file_data: null,
            updated: false,
            loading: false,
        }
    },

    methods: {
        click1() {
            this.$refs.input1.click()
        },

        previewImage(event) {
            this.loading = true;
            this.uploadValue = 0;
            this.img1 = null;
            this.file_data = event.target.files[0];
            this.file_type = this.file_data.type.split("/")[0];
            this.onUpload()
        },

        onUpload() {
            this.img1 = null;
            const storageRef = firebase.storage().ref(`${this.file_data.name}`).put(this.file_data);
            storageRef.on(`state_changed`, snapshot => {
                this.uploadValue = (snapshot.bytesTransferred / snapshot.totalBytes)*100;
            },
            error => {
                console.error(error.message)
                this.loading = false;
            },
            () => {
                this.uploadValue=100;
                storageRef.snapshot.ref.getDownloadURL().then((url) => {
                    this.img1 = url;
                    const media = {
                        type: this.file_type,
                        url: url
                    }
                    this.form.media = media;
                    this.$store.commit('content/setBlockData', {location: this.location, input: 'media', value: media})
                    this.loading = false;
                });
            });
        },

        remove_file(){
            this.$refs.input1.value = null
            this.img1 = null;
            this.form.media = null;
            this.$store.commit('content/setBlockData', {location: this.location, input: 'media', value: null})
        }
    },

    watch: {
        embed_input: function (new_value) {
            if (new_value) {
                console.log(new_value);
                const url = new_value.split("/");
                console.log(url);
                const embed = {
                    type: 'youtube',
                    url: `https://www.youtube.com/embed/${url.pop()}`
                }

                this.$store.commit('content/setBlockData', {location: this.location, input: 'embed_url', value: embed})
            } else {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'embed_url', value: null})
            }
        }
    },

    computed: {
        embed_url: {
            get () {
                const location_key = this.location.split(',');
                return this.$store.state.content.locations[location_key[0]][location_key[1]].data.embed_url
            },
            set (value) {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'embed_url', value: value})
            }
        },
        media: {
            get () {
                const location_key = this.location.split(',');
                return this.$store.state.content.locations[location_key[0]][location_key[1]].data.media
            },
            set (value) {
                this.$store.commit('content/setBlockData', {location: this.location, input: 'media', value: value})
            }
        }
    }
}
</script>