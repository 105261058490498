<template>
    <div>
        <section v-if="artefact">
            <div class="w-full mb-4 shadow-lg bg-white dark:bg-gray-700 items-right h-16 rounded-2xl z-40">
                <div class="p-4 text-right z-20 flex flex-row justify-end h-full flex-end">

                    <Button
                        @click.native="update_artefact()"
                        :disabled="!updated"
                        btntype="primary"
                        :loading="loading">
                        <i class="fas fa-save"></i> Save
                    </Button>

                    <button
                        :disabled="!updated"
                        type="button"
                        class="px-4 mx-3 bg-gray-600 hover:bg-gray-700 focus:ring-gray-500 focus:ring-offset-gray-200 text-white transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg ">
                        cancel
                    </button>

                </div>
            </div>

            <div class="w-full shadow-lg bg-white dark:bg-gray-700 items-right rounded-2xl z-40">
                <div class="flex flex-col w-full px-4 py-8 bg-white rounded-lg shadow dark:bg-gray-800 sm:px-6 md:px-8 lg:px-10">
                    <p class="mb-4">Title and description of Artefact for internal usage only and is not visible to the public.</p>
                    <form action="#" autoComplete="off">
                        <h1>Title</h1>
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <input
                                    type="text"
                                    class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    v-model="title"
                                    placeholder="Artefact Title"
                                />
                            </div>
                        </div>

                        <h1>Description</h1>
                        <div class="flex flex-col mb-2">
                            <div class="flex relative ">
                                <textarea
                                    type="text"
                                    class="rounded-r-lg flex-1 appearance-none border border-gray-300 w-full py-2 px-4 bg-white text-gray-700 placeholder-gray-400 shadow-sm text-base focus:outline-none focus:ring-2 focus:ring-purple-600 focus:border-transparent"
                                    v-model="description"
                                    placeholder="Artefact Title"
                                />
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <FormBuilder
                :template="artefact.template"
                :content="artefact.content"/>
        </section>
    </div>
</template>

<script>
import RestResource from '@/services/RestResource'
import FormBuilder from '@/components/FormBuilder';
import Button from '@/components/Global/Button';
import { mapGetters } from 'vuex'

export default {
    components: {
        FormBuilder,
        Button
    },

    data () {
        return {
            artefact: {},
            title: "",
            description: "",
            updated: false,
            loading: false,
        }
    },

    mounted () {
        this.get_artefact(this.$route.params.id)
    },

    methods: {
        async get_artefact (id) {
            const restResouceService = new RestResource();
            const { data } = await restResouceService.GETRequest(`artefacts/${id}`);
            this.$store.dispatch('content/set', data.data.content);
            this.artefact = data.data;
            this.title = this.artefact.title;
            this.description = this.artefact.description;
        },

        async update_artefact () {
            try {
                this.loading = true;
                const content = {
                    title: this.title,
                    description: this.description,
                    content: this.content
                };
                const restResouceService = new RestResource();
                await restResouceService.PATCHRequest(`artefacts/${this.artefact.id}`, content);

                this.loading = false;

                this.$notify({
                    group: 'foo',
                    type: 'success',
                    title: 'Artefact Updated ',
                    text: 'Successfully updated artefact.'
                });
            } catch (err) {
                console.error(err);
                this.loading = false;
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Error',
                    text: err.message
                });
            }
        }
    },

    watch: {
        title: function (new_value, old_value) {
            if (old_value) {
                this.updated = new_value;
            }
        },

        description: function (new_value, old_value) {
            if (old_value) {
                this.updated = new_value;
            }
        },

        '$store.state.content.updated': function (new_value) {
            this.updated = new_value;
        }
    },

    computed: {
        ...mapGetters({
            content: 'content/locations'
        })
    }
}
</script>