<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50 py-12 px-4 sm:px-6 lg:px-8">
    <div class="max-w-md w-full space-y-8">
        <div>
        <img class="mx-auto h-12 w-auto" src="@/assets/m.svg" alt="Workflow">
        <h2 class="mt-6 text-center text-3xl font-extrabold text-gray-900">
            Reset Password
        </h2>
        <p class="mt-2 text-center text-sm text-gray-600">
            Enter a new password below, to log back into your account.
        </p>

        </div>
        <form class="mt-8 space-y-6" id="login-form" @submit="login" v-on:submit.prevent>

        <div class="rounded-md shadow-sm -space-y-px">
            <div>
                <label for="password" class="sr-only">Password</label>
                <input id="password" name="password" type="password" autocomplete="current-password" v-model="form.password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Password">
            </div>
            <div>
                <label for="confirm-password" class="sr-only">Password</label>
                <input id="confirm-password" name="confirm-password" type="password" autocomplete="current-password" v-model="form.confirm_password" required class="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm" placeholder="Confirm Password">
            </div>
        </div>

        <div class="flex items-center justify-between">
            <div class="text-sm">
            <a href="/login" class="font-medium text-indigo-600 hover:text-indigo-500">
                Back to login
            </a>
            </div>
        </div>

        <div>
            <button type="submit" class="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
            <span class="absolute left-0 inset-y-0 flex items-center pl-3">
                <!-- Heroicon name: solid/lock-closed -->
                <svg class="h-5 w-5 text-indigo-500 group-hover:text-indigo-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M5 9V7a5 5 0 0110 0v2a2 2 0 012 2v5a2 2 0 01-2 2H5a2 2 0 01-2-2v-5a2 2 0 012-2zm8-2v2H7V7a3 3 0 016 0z" clip-rule="evenodd" />
                </svg>
            </span>
            Reset Password
            </button>
        </div>
        </form>
    </div>
    </div>
</template>

<script>
export default {
    data () {
        return {
            form: {
                password: "",
                confirm_password: "",
                oobCode: this.$route.query.code,
                email: this.$route.query.email
            }
        }
    },

    methods: {
        login () {
            if (this.form.password === this.form.confirm_password) {
                fetch(`${process.env.VUE_APP_API_ROUTE}auth/reset-password-confirm`, {
                    method: 'POST',
                    mode: 'cors', // no-cors, *cors, same-origin
                    cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
                    credentials: 'same-origin', // include, *same-origin, omit
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    redirect: 'follow', // manual, *follow, error
                    referrerPolicy: 'no-referrer',
                    body: JSON.stringify(this.form)
                })
                .then(response => response.json())
                .then(() => {
                    this.$notify({
                        group: 'foo',
                        type: 'success',
                        title: 'Password Reset ',
                        text: 'Successfully reset your password, you may now login with your new credentials.'
                    });

                    this.$router.push('/')

                })
                .catch(err => {
                    console.error(err);
                })
            } else {
                console.error("Your password must match.")
                this.$notify({
                    group: 'foo',
                    type: 'error',
                    title: 'Password must match'
                });
            }
        }
    }
}
</script>